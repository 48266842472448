<script lang="ts">
    import { isAuthMenuOpened, userProfile } from '_auth/stores/auth.store'
    import { goto } from '$app/navigation'
    import { navAuth } from '_auth/_config/constants/menu'
    import { logout } from '_auth/helpers/auth.helper'
    import { managementUrl } from 'stores/url.store'

    function handleClick(action: string) {
        $isAuthMenuOpened = false
        switch (action) {
            case 'logout':
                return logout()
            case 'management':
                return goto(`${$managementUrl}`)

            default:
                return goto(action)
        }
    }
</script>

<section class:open={$isAuthMenuOpened}>
    <div>
        <a href="/" on:click|preventDefault={() => handleClick('/')}>
            <img src="/assets/images/bidr_logo_registered.svg" alt="Bidr" />
        </a>
        <img
            src="/assets/images/close_icon.svg"
            alt="Close"
            on:click={() => {
                $isAuthMenuOpened = false
            }}
        />
        <div>
            <ul>
                {#each navAuth.authenticated as { label, action, role }}
                    {#if role}
                        {#if $userProfile?.role?.key.toString() == role}
                            <li on:click={() => handleClick(action)}>{label == 'account' ? 'My Profile' : label}</li>
                            <hr />
                        {/if}
                    {:else}
                        <li on:click={() => handleClick(action)}>{label}</li>
                    {/if}
                {/each}
            </ul>
        </div>
    </div>
</section>
<div class="background-cover" class:open={$isAuthMenuOpened} />

<style lang="scss">
    section {
        z-index: 100;

        display: grid;
        grid-template-rows: max-content 1fr;
        justify-items: end;
        align-items: center;
        justify-content: start;

        background-color: var(--color-accent);
        color: var(--color-accent);
        font-size: var(--font-size);
        min-height: 100vh;

        position: absolute;
        top: 0;
        right: -500rem;

        transition: right 0.1s;

        &.open {
            /* right: 0; */
            left: 100vw;
            transform: translateX(calc(-100% + 1px));
            width: 92vw;
        }

        > div {
            width: 92vw;

            a {
                margin: 2rem 10rem;
                width: 100%;
                img {
                    width: 8rem;
                    transform: translate(-70px, 0px);
                }
            }

            > img {
                position: absolute;
                right: 2rem;
                top: 1rem;

                cursor: pointer;
                transform: translate(-12px, 16px);
            }

            .greeting {
                div {
                    padding: 0;
                }
            }

            div:not(.greeting) {
                display: grid;
                align-content: space-between;
                justify-content: end;

                width: 100%;
                height: 100%;
                padding: 0 2.9rem;

                text-align: right;
                color: var(--color-white);

                ul {
                    height: 100%;
                    width: 76vw;

                    li {
                        list-style-type: none;
                        cursor: pointer;
                        color: var(--color-white);
                    }

                    hr {
                        border-top: 0.1rem solid var(--color-white);
                        // margin: 0.1rem 0 0;
                    }
                }
            }

            div {
                text-align: left;
                padding: 2rem 2.9rem;
                color: var(--color-white);
            }
        }
    }
    .background-cover {
        z-index: 99;

        background-color: var(--color-grey);
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh;

        display: none;
    }

    :global(header:not(.isMobile)) {
        section {
            @media (min-width: 1025px) {
                display: none;
            }

            &.open {
                @media (min-width: 1025px) {
                    display: none;
                }
            }

            .background-cover {
                &.open {
                    display: block;
                }
            }
        }
    }
</style>
