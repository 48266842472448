<script lang="ts">
    import { userProfile, isAuthMenuOpened } from '_auth/stores/auth.store'
    import { goto } from '$app/navigation'
    import { navAuth } from '_auth/_config/constants/menu'
    import { logout } from '_auth/helpers/auth.helper'
    import { managementUrl } from 'stores/url.store'

    function handleClick(action: string) {
        $isAuthMenuOpened = false
        switch (action) {
            case 'logout':
                return logout()
            case 'management':
                return goto(`${$managementUrl}`)

            default:
                return goto(action)
        }
    }
</script>

<section class:open={$isAuthMenuOpened}>
    <div>
        <b>
            <ul>
                {#each navAuth.authenticated as { label, action, role }}
                    {#if role}
                        {#if $userProfile?.role?.key.toString() == role}
                            <li on:click={() => handleClick(action)}>{label == 'account' ? 'My Profile' : label}</li>
                        {/if}
                    {:else}
                        <li on:click={() => handleClick(action)}>{label}</li>
                    {/if}
                {/each}
            </ul>
        </b>
    </div>
</section>

<style lang="scss">
    section {
        display: none;

        grid-template-rows: max-content 1fr;
        justify-items: end;
        align-items: center;
        justify-content: end;

        background-color: var(--color-accent);
        color: var(--color-white);
        font-size: var(--font-size);
        border-radius: 0.4rem;

        height: min-content;
        width: min-content;
        position: absolute;

        right: 0;

        &.open {
            display: grid;
        }

        div {
            b {
                display: grid;
                align-content: space-between;
                justify-content: center;

                width: 100%;
                height: 100%;
                padding: 1rem;

                text-align: right;

                ul {
                    height: 100%;
                    width: 17rem;

                    text-align: right;

                    li {
                        list-style-type: none;
                        cursor: pointer;
                        margin: 1.2rem 0 1.8rem;
                        color: var(--color-white);
                    }
                }
            }

            div {
                text-align: right;
                padding: 0 2rem;
            }
        }
    }
    :global(:not(.isMobile)) {
        section.open {
            @media (max-width: 1024px) {
                display: none;
            }
        }
    }
</style>
