export const navAuth: AuthNav = {
    unauthenticated: [
        {
            label: 'Log In',
            action: 'login',
            style: 'contained',
            primary: false,
        },
        {
            label: 'Sign Up',
            action: '/signup',
            style: 'outlined',
            primary: false,
        },
    ],
    authenticated: [
        { label: 'account', action: '/user/profile', primary: true, role: 'Buyer' },
        { label: 'bidr for Agents', action: 'management', primary: true, role: 'Agent' },
        { label: 'Logout', action: 'logout', primary: true, role: null },
    ],
}
