<script type="ts">
    export let menu: Menus[]

    import AuthNav from '_auth/components/auth-nav.svelte'
    import MainNav from 'components/page/nav/menu-items.svelte'
    import MobileNav from 'components/page/nav/nav-mobile.svelte'
    import { isMobile, isMobileMenuOpened } from '@cucumber/ui/stores/ui.store'

    import { goto } from '$app/navigation'
    import { isApple } from '@cucumber/ui/stores/ui.store'
    import { browser } from '$app/env'

    let navDrawerOpen = false

    let scrollY2: number = 0

    function handleClick(destination: string) {
        navDrawerOpen = false

        goto(destination)
    }

    function handleMobileMenuClick() {
        $isMobileMenuOpened = true
    }

    $: showShadow = scrollY2 > 0

    $: if (browser && !!navigator.userAgent.toLowerCase().match(/mobile/g)) $isMobile = !!navigator.userAgent.toLowerCase().match(/mobile/g)
</script>

<svelte:window bind:scrollY={scrollY2} />

<header class:isOpened={navDrawerOpen} class:showShadow class:isMobile={$isMobile}>
    <div class:apple={$isApple}>
        <a class="hamburger-icon" href="/" on:click|preventDefault={() => handleMobileMenuClick()}>
            <img src="/assets/images/mobile_menu_icon.svg" alt="mobile-menu" />
        </a>

        <a href="/" on:click|preventDefault={() => handleClick('/')}>
            <img src="/assets/images/bidr_logo_registered.svg" alt="Bidr" />
        </a>

        <b>
            <ul>
                <MainNav {menu} inline={true} />
            </ul>
        </b>

        <AuthNav />
    </div>

    <MobileNav {menu} />
</header>

<style lang="scss">
    header {
        transition: all 0.1s;
        z-index: 99;

        background-color: var(--color-accent);
        position: fixed;
        top: 0;
        left: 0;
        height: var(--header-height);

        width: 100%;
        max-width: none;

        @media (min-width: 1920px) {
            left: 0;
            margin: 0;
            transform: none;
        }

        &.showShadow {
            box-shadow: 0rem -0.4rem 1rem var(--color-secondary);
            height: var(--header-height);
        }

        div {
            display: grid;

            grid-template-columns: max-content 1fr minmax(10%, max-content);
            grid-gap: var(--column-gap);

            justify-content: center;
            align-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-width: fit-content;

            padding: var(--site-padding);

            ul {
                justify-self: center;

                background-color: transparent;
                display: grid;
                grid-auto-flow: column;
                align-content: center;
                height: 100%;
                list-style: none;
                width: min-content;
                padding: 0;
                margin: 0;

                :global(span) {
                    cursor: pointer;
                }
                :global(span:hover) {
                    text-decoration: underline;
                }
            }

            .hamburger-icon {
                display: none;
                line-height: 0;

                @media (max-width: 1024px) {
                    padding: 0 3rem 0 0;
                    display: block;
                }

                img {
                    animation: unset;
                }
            }

            a:not(.hamburger-icon) {
                line-height: 0;

                img {
                    width: 12rem;

                    @media (max-width: 1024px) {
                        width: 10rem;
                    }

                    @media (max-width: 450px) {
                        width: 8rem;
                    }
                }
            }

            b {
                z-index: 99;
                justify-self: center;

                @media (max-width: 1024px) {
                    display: none;
                }
            }

            @media (min-width: 769px) {
                margin: 0 auto;
            }

            @media (max-width: 1024px) {
                padding: 0 2rem;
            }
        }
        .apple {
            display: flex;
            justify-content: space-between;
            b {
                ul {
                    padding-left: 2.3rem;
                }
            }
            @media (max-width: 1024px) {
                a:nth-child(2) {
                    flex: auto;
                }
            }
        }
    }

    .isOpened {
        height: 100vh;
        background-color: var(--color-accent);
        grid-template-rows: auto 6rem auto auto 1fr;
        padding: 2rem 0rem 5.1rem 0rem;

        @media (min-width: 769px) {
            height: auto;
            background-color: var(--color-primary);
            padding: 2rem;

            grid-template-rows: auto auto;
        }
    }
</style>
