<script lang="ts">
    export let menu: Menus[]
    export let inline: boolean = false

    import { clickOutside } from 'helpers/custom-event.helper'
    import { isAuthenticated } from '_auth/stores/auth.store'
    import { isMobileMenuOpened } from '@cucumber/ui/stores/ui.store'
    import { page } from '$app/stores'

    let targetedMenuItemIndex: number
    let menuOpenState: boolean[] = menu.map(item => true)

    function handleMenuClick(menu: string, index: number) {
        targetedMenuItemIndex = index

        if (menuOpenState[index] == undefined) menuOpenState.push(!menuOpenState[index])
        else menuOpenState[index] = !menuOpenState[index]
    }

    function closeMobileMenu() {
        if ($isMobileMenuOpened) $isMobileMenuOpened = false
    }
</script>

{#each menu as item, i}
    {#if item.status == 'published' && (item.page?.name || item.children?.length)}
        {#if !item?.page?.authentication_required || $isAuthenticated}
            <li>
                {#if item.children?.length}
                    <div>
                        {#if item?.page}
                            <a href={item?.page?.name == 'home' ? '/' : '/' + item?.page?.name} class:selected={item?.page?.name == $page.url.pathname} on:click={closeMobileMenu}>
                                {item.label ?? item.name}
                            </a>
                        {:else}
                            <span style="text-decoration: none;">{item.label ?? item.name}</span>
                        {/if}

                        {#if !$isMobileMenuOpened}
                            <i
                                class="fas"
                                on:click={() => {
                                    handleMenuClick(item.label ?? item.name, i)
                                }}
                                use:clickOutside
                                on:click_outside={() => {
                                    if (targetedMenuItemIndex == i) menuOpenState[i] = true
                                }}
                            />
                        {/if}
                    </div>

                    <ul class:hide={inline && menuOpenState[i]} class:inline>
                        <svelte:self menu={item.children?.flatMap(i => i?.related_menus_id ?? [])} />
                    </ul>
                {:else if item.page?.name}
                    <a href={item.page.name == 'home' ? '/' : '/' + item.page.name} class:selected={item.page.name == $page.url.pathname} on:click={closeMobileMenu}>
                        {item.label ?? item.page.title ?? item.name}
                    </a>
                {/if}
            </li>
        {/if}
    {/if}
{/each}

<style lang="scss">
    li {
        padding: 0;
        margin: 0;

        @media (max-width: 1024px) {
            word-break: break-word;
        }

        a,
        span {
            color: var(--color-primary);
            padding: 0.9rem 3rem;
            white-space: nowrap;
            width: max-content;

            font-weight: var(--font-weight-regular);
            font-size: var(--font-size);
            line-height: 0.5rem;

            padding: 2.9rem 3rem 3.4rem 0.8rem;

            display: inline-block;
            height: 3.7rem;
            cursor: pointer;

            @media (max-width: 1024px) {
                width: 100%;
                white-space: unset;
                line-height: 2rem;
                margin-bottom: 1rem;
            }
        }

        span {
            padding: 2.9rem 1rem 3.4rem 0.8rem;
        }

        div {
            display: grid;
            grid-template-columns: 1fr min-content;
            align-items: center;
            margin: 0 3rem 0 0;

            a {
                padding: 2.9rem 1rem 3.4rem 0.8rem;
            }

            i {
                cursor: pointer;

                border: 0;
                color: var(--color-white);
                padding: 0;

                &::before {
                    content: '\f0d7';
                    font-size: 1.5rem;
                }
            }
        }

        :global(ul li span),
        :global(ul li a),
        :global(ul li i) {
            color: var(--color-primary) !important;
            // padding: 1.9rem 3rem 2.4rem 3.8rem !important;
            padding: 0 0 0 3.8rem !important;
        }

        ul {
            margin: 0;
            list-style: none;

            &.inline {
                margin: 0rem 0 0;
                position: absolute;
                background: var(--color-accent);
                border-radius: 0 0 var(--border-radius) var(--border-radius);

                a {
                    color: var(--color-white) !important;
                    padding: 1.9rem 3rem 2.4rem 1.8rem !important;
                    cursor: pointer;
                }
            }

            &.hide {
                display: none;
            }
        }
    }
</style>
