<script lang="ts">
    export let menu: Menus[]

    import { isMobileMenuOpened } from '@cucumber/ui/stores/ui.store'
    import MainNav from 'components/page/nav/menu-items.svelte'
    import { goto } from '$app/navigation'

    function handleClick(destination: string) {
        $isMobileMenuOpened = false
        goto(destination)
    }
</script>

<section class:open={$isMobileMenuOpened}>
    <div>
        <a href="/" on:click|preventDefault={() => handleClick('/')}>
            <img src="/assets/images/bidr_logo_registered.svg" alt="Bidr" />
        </a>
        <img
            src="/assets/images/close_icon.svg"
            alt="Close"
            on:click={() => {
                $isMobileMenuOpened = false
            }}
        />

        <b>
            <ul>
                <MainNav {menu} />
            </ul>
        </b>
    </div>
</section>
<div class="background-cover" class:open={$isMobileMenuOpened} />

<style lang="scss">
    section {
        z-index: 100;

        min-height: 100vh;
        background-color: var(--color-accent);
        position: absolute;
        top: 0;
        left: -500rem;

        display: grid;
        grid-template-rows: max-content 1fr;
        justify-items: start;
        align-items: center;
        justify-content: start;

        transition: left 0.1s;
        &.open {
            left: 0;
        }

        div {
            width: 92vw;
            height: 100vh;

            a {
                margin: 2rem 10rem;
                img {
                    width: 8rem;
                    transform: translate(-7.2rem, 0);
                }
            }

            > img {
                position: absolute;
                right: 2rem;
                top: 1rem;

                cursor: pointer;
                transform: translate(-12px, 16px);
            }

            b {
                width: 100%;
                height: 100%;
                display: grid;
                align-content: space-between;
                justify-content: start;

                overflow-y: scroll;
                padding-bottom: 20rem;

                ::-webkit-scrollbar {
                    width: 10px;
                }
                ::-webkit-scrollbar-track {
                    background: transparent;
                }
                ::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 10px;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }

                ul {
                    justify-self: center;

                    background-color: transparent;
                    height: 100%;
                    list-style: none;
                    width: 100%;
                    // padding: 0;
                    padding: 0 2rem;
                }
            }
        }
    }

    .background-cover {
        background-color: var(--color-grey);
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh;

        z-index: 99;
        display: none;
        &.open {
            display: block;
        }
    }
</style>
