export const navMain: Menus[] = [
    {
        name: '/home',
        label: 'Home',
        children: [],
        status: 'published',
    },
    {
        name: '/buying',
        label: 'Buying',
        children: [],
        status: 'published',
    },
    {
        name: '/selling',
        label: 'Selling',
        children: [],
        status: 'published',
    },
    {
        name: '/about',
        label: 'About bidr',
        children: [],
        status: 'published',
    },
    {
        name: '/support',
        label: 'Support',
        children: [],
        status: 'published',
    },
    {
        name: '/contact',
        label: 'Contact',
        children: [],
        status: 'published',
    },
]
export const navFooter: Menus = {
    name: 'footer-nav',
    label: 'Support',
    children: [
        {
            related_menus_id: {
                name: 'home',
                label: 'Home',
                page: {
                    name: '/home',
                    status: 'published',
                },
                status: 'published',
            },
        },
        {
            related_menus_id: {
                name: '/about',
                label: 'About',
                status: 'published',
            },
        },
    ],
}

export const menuToggleIcons = [
    { src: '/assets/images/close.svg', alt: 'close menu' },
    { src: '/assets/images/hamburger_menu.svg', alt: 'open menu' },
]
