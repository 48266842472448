<script lang="ts" context="module">
    import { authorize_token_url, authorize_token_url_refresh, end_session_url, login_url, silent_renew_in_seconds } from '_auth/stores/provider.store'
    import { parseParamsToUrl } from '@cucumber/ui/helpers/url'

    export function defineAuthStoresFromLoad(session: App.Session) {
        let config = {
            ReturnUrl: session.returnPath,
            client_id: session.clientId,
            redirect_uri: session.redirectUrl + '/silent_renew.html',
            response_type: session.responseType,
            scope: session.scope,
            nonce: session.nonce,
            state: session.state,
            prompt: 'none',
        }

        login_url.set(session.authBaseUrl + session.loginPath + parseParamsToUrl(config, { delimiter: '%26' }))
        authorize_token_url.set(session.authBaseUrl + session.refreshPath + parseParamsToUrl(config))

        config.redirect_uri = session.redirectUrl + '/silent_renew_token.html'
        authorize_token_url_refresh.set(session.authBaseUrl + session.refreshPath + parseParamsToUrl(config))

        end_session_url.set(`${session.authBaseUrl}${session.endSessionPath}post_logout_redirect_uri=${session.redirectUrl}`)
        silent_renew_in_seconds.set(session.silentRenewInSeconds)
    }
</script>

<script lang="ts">
    import { browser } from '$app/env'
    import { onMount } from 'svelte'

    import { authenticatedUserId, userProfile, isAuthenticated } from '_auth/stores/auth.store'

    import { cookieCheck, getCookieData, handleHashChange, handleToken, logout } from '_auth/helpers/auth.helper'
    import { runQueryWithPromise } from 'helpers/graphql-query'
    import { getClient } from '@urql/svelte'
    import { UserProfileDocument } from '_config/graphql/tags-generated'
    import { goto } from '$app/navigation'

    let silentRenewFrame: any
    const client = getClient()

    let checkAuth: boolean = false

    function setUpSilentRenewFrame() {
        if (!silentRenewFrame) {
            setInterval(getToken, Number($silent_renew_in_seconds) * 1000)
        }

        // @ts-ignore
        silentRenewFrame = window.frames['silent_renew'].contentWindow
    }

    function getToken() {
        silentRenewFrame.refreshToken($authorize_token_url_refresh)
    }

    function removeCheckAuth() {
        localStorage.removeItem('id_token')
        localStorage.removeItem('access_token')
        localStorage.removeItem('after_logged_in')

        checkAuth = $isAuthenticated ? true : false
        if (checkAuth) {
            window.location.reload()
        }
    }

    onMount(() => {
        window.setUpSilentRenewFrame = setUpSilentRenewFrame
        window.handleHashChange = handleHashChange
        window.removeCheckAuth = removeCheckAuth

        const tokensHash = getTokens('hash')
        if (tokensHash.id_token && tokensHash.access_token) {
            const result = handleToken(tokensHash.id_token, tokensHash.access_token)
            if (result) {
                setUpSilentRenewFrame()
            }

            return
        }

        const tokensLocalstorage = getTokens('localstorage')
        if (tokensLocalstorage.id_token && tokensLocalstorage.access_token) {
            handleToken(tokensLocalstorage.id_token, tokensLocalstorage.access_token)
        }

        checkAuth = true

        return
    })

    function getTokens(method: string) {
        let tokens: Tokens = {
            id_token: null,
            access_token: null,
        }

        switch (method) {
            case 'hash':
                const params = new URLSearchParams(location.hash.substring(1))
                tokens.id_token = params.get('id_token')
                tokens.access_token = params.get('access_token')
                break

            case 'localstorage':
                tokens.id_token = localStorage.getItem('id_token')
                tokens.access_token = localStorage.getItem('access_token')
                break
        }

        return tokens
    }

    async function getUserInfo() {
        const result = await runQueryWithPromise(UserProfileDocument, { userID: $authenticatedUserId! } as SomeObject, client)
        if (result?.error) goto(`/error#badUserInfo`)

        let currentUser = result?.data?.userById as UserProfile

        if (!currentUser) return logout()

        $userProfile = currentUser

        const cookie = getCookieData()
        document.cookie = `${cookie.name}=yes;path=/;domain=${cookie.domain};`

        const cookieLogin = getCookieData('IsNewLogin')
        document.cookie = `${cookieLogin.name}=yes;path=/;domain=${cookie.domain};`

        setInterval(() => {
            cookieCheck()
        }, 5000)
    }

    $: if (!!$authenticatedUserId) getUserInfo()
</script>

{#if (browser && window.location == window.parent.location && ($isAuthenticated || document.referrer.includes('silent_renew') || location.hash.includes('token'))) || checkAuth}
    <iframe src={$authorize_token_url_refresh} frameborder="0" id="silent_renew" title="silent_renew" />
{/if}

<style lang="scss">
    iframe {
        position: absolute;
        width: 0;
        height: 0;
        clip-path: circle(0);
    }
</style>
