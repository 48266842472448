import { createClient, dedupExchange, fetchExchange, subscriptionExchange } from '@urql/svelte'
import { cacheExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import { createClient as createWSClient } from 'graphql-ws'

import { browser } from '$app/env'
import { get } from 'svelte/store'
import { accessToken } from '_auth/stores/auth.store'
import { webSocketClient } from 'stores/ws-client.store'

export function createGraphqlWSClient(graphqlWSUrl: string) {
    const wsClient = createWSClient({
        url: graphqlWSUrl,
        webSocketImpl: WebSocket,
    })

    webSocketClient.set(wsClient)
}

export function createGraphqlClient(graphqlUrl: string) {
    return createClient({
        url: graphqlUrl,
        fetchOptions: () => {
            const token = get(accessToken)
            return {
                headers: { authorization: token ? `Bearer ${token}` : '' },
            }
        },

        exchanges: [
            subscriptionExchange({
                forwardSubscription: operation => ({
                    subscribe: sink => ({
                        unsubscribe: get(webSocketClient)!.subscribe(operation, sink),
                    }),
                }),
            }),

            dedupExchange,
            requestPolicyExchange({}),
            cacheExchange({
                keys: {
                    AuctionsResponse: () => null, //need that otherwise it will complain missing id or key
                    LotsResponse: () => null,
                    page_main: () => null,
                    custom_marker: () => null,
                    ListingAssessmentDataGroupsResponse: () => null,
                    ListingMediaSizesResponse: () => null,
                    AssessmentDataItemResponse: () => null,
                    ListingAgentResponse: () => null,
                    AgencyListingCategoryResponse: () => null,
                    ListingResponse: () => null,
                    menus_menus: () => null,
                    AssessmentData: () => null,
                },
                resolvers: {
                    Query: {
                        // past_winners_by_id: (parent, args) => ({ __typename: 'past_winners', id: args.id }),
                    },
                },
                storage: browser
                    ? makeDefaultStorage({
                          idbName: 'dxm-v1',
                      })
                    : undefined,
            }),
            fetchExchange,
        ],
        requestPolicy: 'cache-and-network',
    })
}
