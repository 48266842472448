export function replaceQueryParams(values: Record<string, string>): void {
    const url = new URL(window.location.toString())
    for (let [k, v] of Object.entries(values)) {
        if (!!v) {
            url.searchParams.set(encodeURIComponent(k), encodeURIComponent(v))
        } else {
            url.searchParams.delete(k)
        }
    }
    history.replaceState({}, '', url)
}

export function parseParamsToUrl(params: SomeObject, config?: { delimiter?: string }): string {
    return Object.keys(params)
        .map(function (key: string) {
            switch (key) {
                case 'ReturnUrl':
                    return key + '=' + (params[key] ?? '').replace(/\//gi, '%2F')

                default:
                    return key + '=' + encodeURIComponent(params[key])
            }
        })
        .join(config?.delimiter ?? '&')
}
