<script type="ts">
    import { isAuthenticated, isAuthMenuOpened } from '_auth/stores/auth.store'
    import { navAuth } from '_auth/_config/constants/menu'
    import Button from '@cucumber/ui/components/buttons/button.svelte'
    import AuthMobile from '_auth/components/nav/auth-menu-mobile.svelte'
    import AuthMenu from '_auth/components/nav/auth-menu.svelte'
    import { clickOutside } from 'helpers/custom-event.helper'
    import { userProfile } from '_auth/stores/auth.store'
    import { login } from '_auth/helpers/auth.helper'
    import { goto } from '$app/navigation'

    $: accountName = `${$userProfile?.firstName} ${$userProfile?.lastName}`

    function handleClick(action: string) {
        switch (action) {
            case 'login':
                return login()

            default:
                return goto(action)
        }
    }
</script>

<div class="authNav-container">
    <div class="authNav">
        {#if $isAuthenticated}
            <div
                on:click={() => {
                    $isAuthMenuOpened = !$isAuthMenuOpened
                }}
                use:clickOutside
                on:click_outside={() => {
                    $isAuthMenuOpened = false
                }}
                title="My Profile"
            >
                <div class="account-name">{accountName.includes('undefined') ? '' : accountName}</div>
                <div>
                    <i class="fas" />
                </div>
            </div>
        {:else}
            {#each navAuth.unauthenticated as { label, action, style, primary }}
                <Button {label} {style} {primary} on:click={() => handleClick(action)} />
            {/each}
        {/if}
    </div>
    <div class="auth-menu">
        <AuthMenu />
    </div>
</div>
<AuthMobile />

<style lang="scss">
    .authNav-container {
        display: grid;

        .authNav {
            z-index: 99;

            justify-self: end;

            display: grid;
            grid-auto-flow: column;
            align-content: center;
            height: 100%;
            grid-gap: var(--grid-gap);

            list-style: none;
            font-size: var(--font-size);
            color: var(--color-white);

            @media (max-width: 450px) {
                grid-gap: 1rem;
            }

            @media (max-width: 365px) {
                grid-gap: 0.5rem;
            }

            > div {
                display: grid;

                grid-template-columns: minmax(10%, 100%) max-content;

                align-items: center;
                cursor: pointer;

                div {
                    padding: 0.5rem;

                    i {
                        cursor: pointer;

                        border: 0;
                        color: var(--color-white);
                        padding: 0;

                        &::before {
                            content: '\f0d7';
                            font-size: 1.5rem;
                        }
                    }
                }
                .account-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        :global(button) {
            padding: 1rem 2rem;
            @media (max-width: 450px) {
                padding: 0.7rem 1.7rem;
            }

            @media (max-width: 365px) {
                padding: 0.4rem 1.4rem;
            }
        }

        :global(span) {
            @media (max-width: 450px) {
                font-size: 1.3rem;
            }
            @media (max-width: 365px) {
                font-size: 1rem;
            }
        }

        .auth-menu {
            display: block;
        }
    }
</style>
