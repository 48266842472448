<script type="ts">
    export let menu: Menus
    export let contactUs: Maybe<Plain_Text>

    import { navFooter } from '_config/constants/menu'

    import MainNav from 'components/page/nav/menu-items.svelte'

    $: items = menu.children?.flatMap((i: any) => i.related_menus_id ?? []) ?? navFooter.children!
</script>

<footer>
    <section>
        <b>
            <h2>{menu.label}</h2>
            <ul class="menu">
                <MainNav menu={items} />
                <li>
                    <p class="smaller">&copy; Bidr</p>
                </li>
            </ul>
        </b>

        <b>
            <h2>Follow us on</h2>
            <ul class="social">
                <li>
                    <a href="https://nz.linkedin.com/company/bidrnz" target="_blank">
                        <img src="/assets/images/linked-in.svg" alt="LinkedIn" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/bidrnz/" target="_blank">
                        <img src="/assets/images/instagram.svg" alt="Instagram" />
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/bidrnz/" target="_blank">
                        <img src="/assets/images/facebook.svg" alt="Facebook" />
                    </a>
                </li>
            </ul>
        </b>

        <b>
            <a href="/contact-us">
                <h2>Contact Us</h2>
            </a>
            {@html contactUs?.value}
        </b>
    </section>
</footer>

<style lang="scss">
    footer {
        background: var(--color-accent);
        transition: all 0.3s;
        padding: 3rem 0;

        column-gap: var(--column-gap);

        width: 100%;
        max-width: none;

        section {
            display: grid;
            grid-template-columns: 1fr;

            justify-content: space-between;
            padding: 0 8rem;
            margin: 0 auto;

            @media (max-width: 400px) {
                padding: 0 5rem;
            }

            b {
                display: grid;
                height: fit-content;
                max-width: 30vw;
                width: 20vw;
                margin: 0 0 2rem;
            }
            h2 {
                color: var(--color-white);
            }

            ul {
                // justify-self: center;

                background-color: transparent;
                height: 100%;
                list-style: none;
                width: max-content;
                padding: 0;
                margin: 0;
                // line-height: unset;

                li {
                    display: block;
                    float: left;
                    padding: 0.2rem 0;
                    color: var(--color-white);

                    p {
                        color: var(--color-primary);
                        padding: 1.9rem 3rem 2.4rem 0;
                        margin: 0.5rem 0;
                        // line-height: 0.5rem;
                    }
                }

                &.social {
                    li a {
                        display: grid;
                        align-items: center;
                        grid-template-columns: min-content 1fr;
                        grid-gap: 2rem;
                        margin-bottom: 6rem;
                        white-space: nowrap;
                    }
                    li a img {
                        width: 4rem;
                    }
                }

                &.menu {
                    :global(li) {
                        height: auto;
                        line-height: 2.6rem;
                        width: 20rem;
                    }
                }

                &.menu {
                    :global(a) {
                        padding: 0;
                    }
                }

                &.menu {
                    :global(i) {
                        display: none;
                    }
                }

                :global(li a),
                :global(li span),
                :global(li li a:active),
                :global(li a:active) {
                    // padding: 1.9rem 3rem 2.4rem 0.8rem;

                    // line-height: 0.5rem;
                    height: 2.3rem;
                    color: var(--color-white);
                }
            }

            @media (min-width: 800px) {
                grid-template-columns: repeat(3, max-content);

                b {
                    margin: 0;
                    justify-content: center;
                }
            }

            @media (max-width: 800px) {
                // b:last-child {
                //     width: max-content;
                // }

                b {
                    width: max-content;
                    max-width: unset;
                }
            }

            :global(a),
            :global(p),
            :global(a.active),
            :global(a:active),
            :global(a:focus),
            :global(a:focus-within) {
                color: var(--color-white);
                line-height: 1.5;
            }

            :global(p) {
                margin-top: -0.6rem;
            }

            :global(div) {
                margin: 0;
            }
        }
    }
    .mobileOpen {
        position: fixed;
        display: none;
    }
    .authOpen {
        position: fixed;
        display: none;
    }
</style>
